<template>
  <div>
    <page-description
      :path="[
        { label: 'Modèle contrat', route: 'contrats' },
        { label: 'PV entré', route: 'pventre' },
        { label: 'PV sortie', route: 'pvsortie' },
      ]"
    />
    <Contrat v-if="path == ''" />
    <router-view v-else />
  </div>
</template>

<script>
import Contrat from "../configuration-aide/Contrats/Contrats.vue";
export default {
  components: {
    Contrat,
  },
  data: () => ({
    path: "",
  }),
  created() {
    this.getPath();
  },
  mounted() {
    this.getPath();
  },
  updated() {
    this.getPath();
  },
  methods: {
    getPath() {
      this.path = this.$route.path.replace("/modele-fichier", "");
    },
  },
};
</script>

<style></style>
